.stations {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 30px;
    background: #FFF;
    height: calc(100% - 145px);

    &-header {
        display: flex;
        justify-content: space-between;

        &-title {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }

        &-button {
            &-style {
                border-radius: 50px;
                background: #033677;
                display: flex;
                padding: 14px 16px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            &-icon {
                font-size: 24px;
                color: #FFF;
                margin-right: 8px;
            }

            &-text {
                color: #FFF;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    &-table {
        margin-top: 32px;

        .ant-table-tbody {
            tr {
                td {
                    padding: 0px 10px 0px 0px;
                }
            }
        }

        .ant-table-cell {
            margin: 0px;
            display: table-cell;
            vertical-align: middle;
        }

        .ant-table-thead {
            tr {
                th {
                    color: #B5B5C3;
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.39px;
                    background-color: transparent;
                    padding: 0px 0px 16px 0px;
                }
            }
        }

        .ant-table-column-sorters {
            justify-content: left;
        }

        .ant-table-column-title {
            flex: none;
        }

        &-station {
            display: flex;
            align-items: center;
            margin: 16px 0px;

            &-eye {
                background-color: transparent;
                border: none;
                cursor: pointer;

                &-icon {
                    font-size: 24px;
                    color: #3699FF;
                    padding: 8px;
                    border-radius: 100px;
                    background: #F3F6F9;
                    margin-right: 14px;
                }
            }

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-brand {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-location {
            padding: 8px;
            border-radius: 50px;
            background: #F3F6F9;
            width: max-content;
            display: flex;
            align-items: center;

            &-icon {
                font-size: 18px;
                color: #3699FF;
            }

            &-text {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                margin-left: 10px;
            }
        }

        &-numberofcomments {
            display: flex;
            align-items: center;
            gap: 16px;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            &-button {
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
                width: max-content;
                display: flex;
                align-items: center;
                border: none;

                &-icon {
                    font-size: 18px;
                    color: #3699FF;
                }

                &-text {
                    color: #3699FF;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    margin-left: 10px;
                }
            }
        }

        &-services {
            display: flex;
            align-items: center;
            gap: 16px;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            &-button {
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
                width: max-content;
                display: flex;
                align-items: center;
                border: none;

                &-icon {
                    font-size: 18px;
                    color: #3699FF;
                }

                &-text {
                    color: #3699FF;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    margin-left: 10px;
                }
            }
        }

        &-options {
            &-edit {
                font-size: 18px;
                color: #3699FF;
                padding: 6px 8px;
                border-radius: 50px;
                background: #F3F6F9;
                cursor: pointer;
                border: none;
                text-decoration: none;
            }

            &-delete {
                font-size: 18px;
                color: #3699FF;
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
            }
        }


    }

    &-modal {


        &-ratearea {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 30px;
            background: #FFF;
            padding: 16px;
            margin: 16px 0px;

            .anticon {
                svg {

                    stroke: black !important;
                    /* Yıldızın sınırlarını Aqua yapar */
                    stroke-width: 40px !important;
                    /* Sınır çizgisinin kalınlığı */
                }
            }

            .ant-tabs-nav-operations {
                visibility: hidden !important;
                display: none !important;
            }

            .ant-rate-star-second {
                color: transparent;
            }

            .ant-rate {
                color: #033677;
                font-size: 18px;
            }

            .ant-rate-star {
                margin-inline-end: 3px !important;
            }

            &-average {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 50%;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 8px;
                }

                &-countarea {
                    display: flex;
                    align-items: end;
                    justify-content: space-between;
                    width: 100px;



                    &-rate {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;

                    }

                    &-count {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                }
            }


            &-distribution {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 50%;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-flex {
                    display: flex;
                    align-items: center;
                    justify-content: left;



                    &-number {
                        color: #000;
                        text-align: right;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    &-line {
                        margin: 0px 8px;
                        width: 165px !important;



                        .ant-progress,
                        .ant-progress-outer {
                            width: 100% !important;
                        }

                        .ant-progress-bg {
                            background: #033677;
                        }

                        .ant-progress-inner {
                            border-radius: 50px;
                            background: rgba(3, 54, 119, 0.10);
                        }
                    }

                    &-count {
                        color: #000;
                        text-align: left;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }




                }

            }
        }

        &-stroke {
            border-top: 1px solid black;
            stroke-width: 1px;
            stroke: #000;
            opacity: 0.05;
        }

        &-commentarea {
            display: flex;
            flex-direction: column;

            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 16px 0;

                &-text {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-count {
                    color: rgba(0, 0, 0, 0.50);
                    text-align: right;
                    font-family: "Poppins", sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            &-comment {
                display: flex;
                flex-direction: column;
                padding: 16px;
                border-radius: 30px;
                background: #FAFAFA;
                margin-top: 20px;

                .anticon {
                    svg {

                        stroke: black !important;
                        /* Yıldızın sınırlarını Aqua yapar */
                        stroke-width: 40px !important;
                        /* Sınır çizgisinin kalınlığı */
                    }
                }

                &-profilearea {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;


                    &-profile {
                        display: flex;
                        align-items: center;

                        &-photo {
                            border-radius: 50px;
                            background: #D9D9D9;
                            width: 28px;
                            height: 28px;
                            margin-right: 14px;
                        }

                        &-name {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    &-moreicon {
                        font-size: 24px;
                        color: #000;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                    }
                }

                &-average {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    margin: 8px 0px;
                    width: 100%;

                    &-text {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-right: 8px;
                    }

                    .ant-rate {
                        color: #033677;
                        font-size: 18px;
                    }

                    .ant-rate-star {
                        margin-inline-end: 3px !important;
                    }

                    &-date {
                        color: rgba(0, 0, 0, 0.50);
                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // margin-left: 8px;
                    }
                }

                &-comments {
                    display: flex;
                    align-items: center;
                    width: calc(100% - 32px);
                    margin-bottom: 8px;

                    &-comment {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 8px 0px;
                        width: 100%;

                        :first-child {
                            margin-top: 0px;
                        }

                        :last-child {
                            margin-bottom: 0px;
                        }
                    }

                    &-text {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                &-morebutton {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    margin-top: 16px;

                    &-button {
                        text-decoration: none;
                        border: none;
                        background-color: transparent;
                        cursor: pointer;

                        &-text {
                            color: #033677;
                            text-align: center;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                            letter-spacing: -0.5px;
                            text-decoration-line: underline;

                        }
                    }
                }
            }
        }

        &.ant-modal {
            top: 90px !important;
        }

        .ant-modal-content {
            width: 580px;
            padding: 32px;
            border-radius: 30px;
            background: #EFEFEF;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .ant-modal-close {
            margin-top: 20px;
            margin-right: 20px;
            width: 36px;
            height: 36px;
            padding: 8px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }
    }

    .ant-select {
        display: none !important;
    }

    .anticon-caret-up {
        display: none;
    }

    .anticon-caret-down {
        font-size: 20px;
        color: #3699FF;
    }

    .notification-pagination {
        display: flex;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-label {
        margin-right: 16px;
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ant-pagination-prev {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-next {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-item {
        border: none;
        background-color: transparent;
        min-width: 24px;

        a {
            color: #000;
            font-weight: 100;
            opacity: 0.3;
        }
    }

    .ant-pagination-item-active {
        a {
            color: #000;
            font-weight: bold;
            opacity: 1;
        }
    }

    .ant-table-thead {
        tr {
            th {
                &::before {
                    display: none !important;
                }
            }
        }
    }
}