.sidebar {
  width: 160px;
  position: fixed;
  padding: 16px 8px 65px 8px;
  top: 72px;
  height: calc(100% - 225px);
  background-color: #fff;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  &.active {
    width: 249px;
    padding: 16px 8px 65px 8px;

    .sidebar-nav-drop {
      display: block;
    }

  }



  &-nav {
    display: flex;
    flex-direction: column;


    &-header {
      position: relative;
      padding: 0px 24px;

      span {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: #fff;
        position: relative;
        z-index: 1;
        padding-right: 8px;
        margin: 8px 0px;
      }

      &::before {
        z-index: 0;
        position: absolute;
        top: 50%;
        width: 80%;
        content: "";
        border-top: rgba(0, 0, 0, 0.10) solid 1px;
      }

    }

    &-link {
      display: flex;
      justify-content: left;
      text-decoration: none;
      color: #000;
      padding: 0px 20px;
      height: 58px;
      margin: 8px 0px;
      align-items: center;

      i {
        font-size: 20px;

      }

      &:hover,
      &.active {
        background-color: #033677;
        color: #FFF;
        border-radius: 20px;

        &>* {
          color: #FFF;
        }
      }
    }

    &-title {
      color: rgba(0, 0, 0, 0.50);
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 4px;
      flex: 1;
    }

    &-drop {
      opacity: 0.5;
      display: none;

      &-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  &-copyright {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 10px;
    left: 20px;

    &.active {
      left: 75px;
    }

    &-text {
      color: #000;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 8px;
    }
  }

  @keyframes slideDown {
    from {
      max-height: 0;
    }

    to {
      max-height: 500px;
      /* İçeriğe göre ayarlayın */
    }
  }

  &-submenu {
    border-radius: 20px;
    background: #F2F2F2;
    padding: 20px;
    max-height: auto;
    overflow: hidden;
    animation: slideDown 1s ease-out forwards;



    .sidebar-nav-link {
      height: 20px;
      margin: 8px 0px;
      padding: 8px;

      :first-child {
        margin-top: 0px;
      }

      :last-child {
        margin-bottom: 0px;
      }

      &:hover,
      &.active {
        background-color: #033677;
        color: #FFF;
        border-radius: 20px;

        &>* {
          color: #FFF;
        }
      }
    }

    .sidebar-nav-title {
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

}