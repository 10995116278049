.schedulednotifications {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 30px;
    background: #FFF;
    height: calc(100% - 150px);


    &-header {
        display: flex;
        justify-content: space-between;

        &-title {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }

        &-button {
            &-style {
                border-radius: 50px;
                background: #033677;
                display: flex;
                padding: 14px 16px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            &-icon {
                font-size: 24px;
                color: #FFF;
                margin-right: 8px;
            }

            &-text {
                color: #FFF;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    &-table {
        margin-top: 32px;


        .ant-table-tbody {
            tr {
                td {
                    padding: 0px;
                }
            }
        }

        .ant-table-cell {
            margin: 0px;
            display: table-cell;
            vertical-align: middle;

        }

        .ant-table-thead {
            tr {
                th {
                    color: #B5B5C3;
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.39px;
                    background-color: transparent;
                    padding: 0px 0px 16px 0px;
                }
            }
        }

        .ant-table-column-sorters {
            justify-content: left;
        }

        .ant-table-column-title {
            flex: none;
        }


        &-notification {
            display: flex;
            align-items: center;
            margin: 16px 0px;

            &-eye {
                padding: 8px;
                border-radius: 100px;
                background: #F3F6F9;
                margin-right: 14px;
                border: none;
                cursor: pointer;

                &-icon {
                    font-size: 24px;
                    color: #3699FF;

                }
            }

            &-textarea {
                display: flex;
                flex-direction: column;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                &-body {
                    color: #B5B5C3;
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 4px;
                }
            }
        }

        &-count {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-title {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }

            &-text {
                color: #B5B5C3;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 4px;
            }
        }

        &-date {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-title {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.39px;
            }

            &-date {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }

            &-time {
                color: #B5B5C3;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 4px;
            }
        }

        &-options {

            &-edit {
                padding: 8px;
                border-radius: 100px;
                background: #F3F6F9;
                border: none;
                cursor: pointer;

                &-icon {
                    font-size: 18px;
                    color: #3699FF;
                }
            }

            &-delete {
                padding: 8px;
                border-radius: 100px;
                background: #F3F6F9;
                border: none;
                cursor: pointer;

                &-icon {
                    font-size: 18px;
                    color: #3699FF;
                }
            }
        }
    }

    .anticon-caret-up {
        display: none;
    }

    .anticon-caret-down {
        font-size: 20px;
        color: #3699FF;
    }

    .ant-tag-cyan {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #C9F7F5;
        color: #1BC5BD;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .ant-tag-yellow {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #FFF4DE;
        color: #FFA800;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .ant-tag-red {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #FFE2E5;
        color: #F64E60;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .notification-pagination {
        display: flex;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-label {
        margin-right: 16px;
        margin-top: 16px;
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ant-pagination-prev {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-next {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-item {
        border: none;
        background-color: transparent;
        min-width: 24px;

        a {
            color: #000;
            font-weight: 100;
            opacity: 0.3;
        }
    }

    .ant-pagination-item-active {

        a {
            color: #000;
            font-weight: bold;
            opacity: 1;
        }
    }

    &-modal {
        width: max-content !important;

        &-selected2 {
            .ant-modal-content {
                width: 840px !important;
            }
        }

        &-selected3 {
            .ant-modal-content {
                width: 1280px !important;
            }
        }

        &-public {

            &-form {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-titleimage {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 50%;
                }

                &-content {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 50%;
                    margin-left: 16px;
                }
            }

            &-input {
                .ant-input {
                    border: none;
                    border-radius: 100px;
                    padding: 12px 16px;
                    height: 56px;
                }

                ::placeholder {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    opacity: 0.5;
                }
            }

            &-textarea {
                .ant-input {
                    border: none;
                    border-radius: 20px;
                    padding: 12px 16px;
                    height: 136px;

                }

                ::placeholder {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    opacity: 0.5;
                }
            }

            &-url {
                .ant-input {
                    border: none;
                    border-radius: 100px;
                    padding: 12px 16px;
                    height: 56px;
                }

                ::placeholder {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    opacity: 0.5;
                }
            }

            &-sendbutton {
                display: flex;
                justify-content: flex-end;

                &-style {
                    width: 158px;
                    padding: 24px;
                    border-radius: 50px;
                    background: #033677;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 24px;
                }

                &-text {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }

            .ant-form-item {
                margin-bottom: 16px;
                width: 100% !important;

            }

            .ant-form-item-control-input-content {
                display: flex;
                justify-content: right;

            }

            &-datetime {
                margin-top: 12px;



                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 24px;
                }

                &-datetimearea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;


                    &-datearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {
                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-timearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }
            }

        }

        &-campaign {

            &-targetgroup {

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-selectarea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-personsarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }
                        }
                    }

                    &-brandarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisfuelarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-ownersarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisstationarea {
                        display: flex;
                        flex-direction: column;
                        width: 39%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-expenseinputarea {
                        display: flex;
                        flex-direction: column;
                        width: 29%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-expensecategoryarea {
                        display: flex;
                        flex-direction: column;
                        width: 29%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 12px !important;
                }
            }

            &-content {
                margin-top: 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;

                    &-titleimage {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                    }

                    &-content {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                        margin-left: 16px;
                    }
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-textarea {
                    .ant-input {
                        border: none;
                        border-radius: 20px;
                        padding: 12px 16px;
                        height: 136px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-url {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-sendbutton {
                    display: flex;
                    justify-content: flex-end;

                    &-style {
                        width: 158px;
                        padding: 24px;
                        border-radius: 50px;
                        background: #033677;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 24px;
                    }

                    &-text {
                        color: #FFF;
                        text-align: center;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                    }
                }

                .ant-form-item {
                    margin-bottom: 16px;
                    width: 100% !important;
                }

                &-datetime {
                    margin-top: 12px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &-datetimearea {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 10px;
                        margin-top: 24px;

                        &-datearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {
                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }

                        &-timearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {

                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }
                    }
                }

            }

            &-datetime {
                margin-top: 12px;

                &-button {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 16px;
                    border: 1px solid rgba(0, 0, 0, 0.20);
                    padding: 24px 20px;
                    border-radius: 50px;
                    background: transparent;
                    align-items: center;



                    span {
                        color: #000;
                        text-align: center;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        letter-spacing: -0.5px;

                    }
                }

                &-space {
                    .ant-space-item {
                        &:first-child {
                            width: 100% !important;
                        }
                    }

                }

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-datetimearea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;



                    &-personarea {
                        display: flex;
                        flex-direction: column;
                        width: 32%;

                        .ant-form-item {
                            margin-bottom: 0px !important;
                        }

                        &-input {
                            .ant-input-number {
                                border: none;
                                border-radius: 100px;
                                padding: 12px 16px;
                                height: 56px;
                                width: 100%;

                            }

                            .ant-input-number {
                                &:hover {
                                    border-radius: 100px 100px 100px 100px;
                                }
                            }


                            .ant-input-number-handler-wrap {
                                border-radius: 0 100px 100px 0;
                            }
                        }

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {
                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-datearea {
                        display: flex;
                        flex-direction: column;
                        width: 32%;

                        .ant-form-item {
                            margin-bottom: 0px !important;
                        }

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {
                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-timearea {
                        display: flex;
                        flex-direction: column;
                        width: 32%;

                        .ant-form-item {
                            margin-bottom: 0px !important;
                        }


                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }
            }

            &-sendbutton {
                display: flex;
                justify-content: flex-end;

                &-style {
                    width: 158px;
                    padding: 24px;
                    border-radius: 50px;
                    background: #033677;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                &-text {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }
        }

        &-personalized {

            &-firstarea {
                display: flex;

            }

            &-targetgroup {
                width: 100%;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-selectarea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 16px;

                    &-personsarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }
                        }
                    }

                    &-brandarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisfuelarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;
                        margin-top: 35px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-ownersarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;
                        margin-top: 35px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 12px !important;
                }
            }

            &-datetime {
                margin-top: 12px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }



                &-datetimearea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-datearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {
                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-timearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }
            }

            &-content {
                margin-left: 16px;
                width: 100%;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;

                    &-titleimage {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 49%;
                    }

                    &-content {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                        margin-left: 16px;
                    }
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 54px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-textarea {
                    .ant-input {
                        border: none;
                        border-radius: 20px;
                        padding: 12px 16px;
                        height: 130px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-url {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-sendbutton {
                    display: flex;
                    justify-content: flex-end;

                    &-style {
                        width: 158px;
                        padding: 24px;
                        border-radius: 50px;
                        background: #033677;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-text {
                        color: #FFF;
                        text-align: center;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                    }
                }

                .ant-form-item {
                    margin-bottom: 16px;
                    width: 100% !important;
                }

                &-datetime {
                    margin-top: 12px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &-datetimearea {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 10px;
                        margin-top: 24px;

                        &-datearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {
                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }

                        &-timearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {

                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }
                    }
                }

            }
        }

        &-attractuser {
            &-targetgroup {


                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-selectarea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-personsarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }
                        }
                    }

                    &-brandarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisfuelarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-ownersarea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 12px !important;
                }
            }

            &-datetime {
                margin-top: 12px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-datetimearea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-datearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {
                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-timearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }
            }

            &-content {
                margin-top: 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;

                    &-titleimage {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                    }

                    &-content {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                        margin-left: 16px;
                    }
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-textarea {
                    .ant-input {
                        border: none;
                        border-radius: 20px;
                        padding: 12px 16px;
                        height: 136px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-url {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-sendbutton {
                    display: flex;
                    justify-content: flex-end;

                    &-style {
                        width: 158px;
                        padding: 24px;
                        border-radius: 50px;
                        background: #033677;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-text {
                        color: #FFF;
                        text-align: center;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                    }
                }

                .ant-form-item {
                    margin-bottom: 16px;
                    width: 100% !important;
                }

                &-datetime {
                    margin-top: 12px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &-datetimearea {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 10px;
                        margin-top: 24px;

                        &-datearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {
                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }

                        &-timearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {

                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }
                    }
                }



            }

            .custom-checkbox .ant-checkbox-wrapper {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
            }

            .custom-checkbox .ant-checkbox-inner {
                width: 24px;
                height: 24px;
                border-radius: 100px;
                background-color: white;
                border: 1px solid black;
                transition: all 0.3s;
            }

            .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
                background-color: black;
                border-color: black;
            }

            .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
                border-color: white;
                border-radius: 100px;

            }

            .custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
            .custom-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner {
                border-color: black;
                background-color: black;
            }

            .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 6px;
                height: 12px;
                border: 2px solid white;
                border-top: 0;
                border-left: 0;
                transform: translate(-50%, -50%) rotate(45deg);
                content: '';
            }

            .custom-checkbox .ant-checkbox-input:checked+.ant-checkbox-inner:hover {
                background-color: black;
                border-radius: 100px;
            }

            .ant-checkbox {
                top: 0px;
            }

            .ant-checkbox+span {
                width: 100%;
            }

            .ant-row {
                width: 100%;
            }

            .custom-checkbox {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                /* Checkbox'u ve metni sağa taşır */
                align-items: center;
                /* İçeriklerin dikey hizalamasını düzenler */
            }

            .ant-checkbox-checked:after {
                border: none;
            }

            .ant-checkbox-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

        }

        &-market {
            &-targetgroup {

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-selectarea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-personsarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }
                        }
                    }

                    &-brandarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisfuelarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-ownersarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisstationarea {
                        display: flex;
                        flex-direction: column;
                        width: 39%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-expenseinputarea {
                        display: flex;
                        flex-direction: column;
                        width: 29%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-expensecategoryarea {
                        display: flex;
                        flex-direction: column;
                        width: 29%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 12px !important;
                }
            }

            &-content {
                margin-top: 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &-titleimage {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                    }

                    &-content {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                        margin-left: 16px;
                    }

                    &-screen {
                        margin-top: 0px;
                        display: flex;
                        align-items: start;
                        justify-content: space-between;
                        gap: 16px;
                        margin-top: 12px;


                        .notification-modal-personalized-targetgroup-selectarea-ownersarea {
                            margin-top: 0px;
                        }

                        &-radio {
                            margin-top: 16px;

                            .ant-checkbox-group {
                                width: 100%;
                                display: flex;
                                align-items: stretch;
                                justify-content: space-between;
                            }

                            .ant-checkbox-wrapper {
                                flex: 1;
                                padding: 12px 16px;
                                background-color: #fff;
                                border-radius: 20px;
                                display: flex;
                                flex-direction: row-reverse;

                                span.checkbox-radio+* {
                                    width: 100%;
                                    font-family: Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                            }

                            .ant-checkbox-inner {
                                border-radius: 100%;
                                border-color: #033677;
                                width: 18px;
                                height: 18px;

                                &:hover {
                                    background-color: #033677;
                                }
                            }

                            .ant-checkbox-checked {
                                .ant-checkbox-inner {
                                    background-color: #033677;

                                    &:hover {
                                        background-color: #033677;
                                    }
                                }
                            }

                            .ant-checkbox,
                            .ant-checkbox-wrapper {
                                .ant-checkbox-inner {

                                    &:hover,
                                    &:focus {
                                        background-color: #033677 !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-textarea {
                    .ant-input {
                        border: none;
                        border-radius: 20px;
                        padding: 12px 16px;
                        height: 136px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-url {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-sendbutton {
                    display: flex;
                    justify-content: flex-end;

                    &-style {
                        width: 158px;
                        padding: 24px;
                        border-radius: 50px;
                        background: #033677;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-text {
                        color: #FFF;
                        text-align: center;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                    }
                }

                .ant-form-item {
                    margin-bottom: 16px;
                    width: 100% !important;
                }

                &-datetime {
                    margin-top: 12px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &-datetimearea {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 10px;
                        margin-top: 24px;

                        &-datearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {
                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }

                        &-timearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {

                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }
                    }
                }

            }

            &-sendbutton {
                display: flex;
                justify-content: flex-end;

                &-style {
                    width: 158px;
                    padding: 24px;
                    border-radius: 50px;
                    background: #033677;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-text {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }
        }

        &-sendbutton {
            &-targetgroup {

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-selectarea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-personsarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }
                        }
                    }

                    &-brandarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisfuelarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-ownersarea {
                        display: flex;
                        flex-direction: column;
                        width: 24%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-thisstationarea {
                        display: flex;
                        flex-direction: column;
                        width: 39%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-expenseinputarea {
                        display: flex;
                        flex-direction: column;
                        width: 29%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-expensecategoryarea {
                        display: flex;
                        flex-direction: column;
                        width: 29%;
                        margin-top: 16px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-select-selector {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                                margin-left: 5px;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 12px !important;
                }
            }

            &-content {
                margin-top: 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;


                    &-titleimage {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                    }

                    &-content {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        width: 50%;
                        margin-left: 16px;
                    }

                    &-screen {
                        margin-top: 0px;
                        display: flex;
                        align-items: start;
                        justify-content: space-between;
                        gap: 16px;
                        margin-bottom: 16px;

                        .notification-modal-personalized-targetgroup-selectarea-ownersarea {
                            margin-top: 0px;
                        }

                        &-radio {
                            margin-top: 16px;

                            .ant-checkbox-group {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            .ant-checkbox-wrapper {
                                flex: 1;
                                padding: 12px 16px;
                                background-color: #fff;
                                border-radius: 20px;
                                display: flex;
                                flex-direction: row-reverse;

                                span.checkbox-radio+* {
                                    width: 100%;
                                    font-family: Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                            }

                            .ant-checkbox-inner {
                                border-radius: 100%;
                                border-color: #033677;
                                width: 18px;
                                height: 18px;

                                &:hover {
                                    background-color: #033677;
                                }
                            }

                            .ant-checkbox-checked {
                                .ant-checkbox-inner {
                                    background-color: #033677;

                                    &:hover {
                                        background-color: #033677;
                                    }
                                }
                            }

                            .ant-checkbox,
                            .ant-checkbox-wrapper {
                                .ant-checkbox-inner {

                                    &:hover,
                                    &:focus {
                                        background-color: #033677 !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-textarea {
                    .ant-input {
                        border: none;
                        border-radius: 20px;
                        padding: 12px 16px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-url {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                        height: 56px;

                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }

                &-sendbutton {
                    display: flex;
                    justify-content: flex-end;

                    &-style {
                        width: 158px;
                        padding: 24px;
                        border-radius: 50px;
                        background: #033677;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-text {
                        color: #FFF;
                        text-align: center;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                    }
                }

                .ant-form-item {
                    margin-bottom: 16px;
                    width: 100% !important;
                }

                &-datetime {
                    margin-top: 12px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &-datetimearea {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 10px;
                        margin-top: 24px;

                        &-datearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {
                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }

                        &-timearea {
                            display: flex;
                            flex-direction: column;
                            width: 49%;

                            &-title {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }

                            &-select {

                                .ant-picker {
                                    padding: 12px 16px;
                                    border: none;
                                    border-radius: 100px;
                                    height: 56px !important;
                                    align-items: center;
                                    width: 100%;
                                }

                                ::placeholder {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                svg {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .ant-select-selection-item {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.5;
                                }

                                .ant-select-arrow {
                                    color: #000;
                                }

                            }
                        }
                    }
                }

            }

            &-datetime {
                margin-top: 12px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-datetimearea {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin-top: 24px;

                    &-datearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {
                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }

                    &-timearea {
                        display: flex;
                        flex-direction: column;
                        width: 49%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 4px;
                        }

                        &-select {

                            .ant-picker {
                                padding: 12px 16px;
                                border: none;
                                border-radius: 100px;
                                height: 56px !important;
                                align-items: center;
                                width: 100%;
                            }

                            ::placeholder {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            svg {
                                color: #000;
                                font-size: 16px;
                            }

                            .ant-select-selection-item {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                opacity: 0.5;
                            }

                            .ant-select-arrow {
                                color: #000;
                            }

                        }
                    }
                }
            }

            &-sendbutton {
                display: flex;
                justify-content: flex-end;

                &-style {
                    width: 158px;
                    padding: 24px;
                    border-radius: 50px;
                    background: #033677;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-text {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }
        }

        .ant-select .ant-select-arrow {
            top: 88%
        }

        .custom-checkbox .ant-checkbox-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }

        .custom-checkbox .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 100px;
            background-color: white;
            border: 1px solid black;
            transition: all 0.3s;
        }

        .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
            background-color: black;
            border-color: black;
        }

        .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
            border-color: white;
            border-radius: 100px;

        }

        .custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
        .custom-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner {
            border-color: black;
            background-color: black;
        }

        .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 12px;
            border: 2px solid white;
            border-top: 0;
            border-left: 0;
            transform: translate(-50%, -50%) rotate(45deg);
            content: '';
        }

        .custom-checkbox .ant-checkbox-input:checked+.ant-checkbox-inner:hover {
            background-color: black;
            border-radius: 100px;
        }

        .ant-checkbox {
            top: 0px;
        }

        .ant-checkbox+span {
            width: 100%;
        }

        .ant-row {
            width: 100%;
        }

        .custom-checkbox {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            /* Checkbox'u ve metni sağa taşır */
            align-items: center;
            /* İçeriklerin dikey hizalamasını düzenler */
        }

        .ant-checkbox-checked:after {
            border: none;
        }

        .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .ant-tabs-content-holder {
            margin: 0px;
        }

        .ant-tabs-tab-active {
            border-radius: 50px;
            background: #000;
            padding: 10px 16px;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            .ant-tabs-tab-btn {
                color: #FFF !important;
            }
        }

        .ant-tabs {
            margin: 24px 0 0 0;
        }



        .ant-modal-content {
            width: 824px;
            padding: 32px;
            border-radius: 30px;
            background: #EFEFEF;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .ant-tabs-ink-bar {
            background: none;
        }

        .ant-tabs-nav-wrap {
            &::before {
                display: none;
            }
        }

        .ant-tabs-top>.ant-tabs-nav::before {
            border-bottom: 0px solid transparent;
        }

        .ant-tabs-tab {
            margin: 0 8px 0 0 !important;
            padding: 10px 16px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }

        .ant-modal-close {
            margin-top: 20px;
            margin-right: 20px;
            width: 36px;
            height: 36px;
            padding: 8px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }
    }

    &-deletemodal {
        display: flex;
        align-items: center;
        margin-top: 32px;
        justify-content: center;

        &-delete {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            background: #033677;
            padding: 24px 12px;
            width: 125px;

            &-text {
                color: #FFF;
                text-align: center;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: -0.5px;
            }
        }

        &-cancel {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            padding: 24px 12px;
            width: 125px;
            margin-left: 8px;


            &-text {
                color: #FFF;
                text-align: center;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: -0.5px;
            }
        }


        .ant-modal-content {
            padding: 32px;
            border-radius: 30px;
            background: #EFEFEF;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &-notificationdetailmodal {

        &-delete {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            background: #033677;
            padding: 24px 12px;
            width: 125px;

            &-text {
                color: #FFF;
                text-align: center;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: -0.5px;
            }
        }

        &-cancel {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            padding: 24px 12px;
            width: 125px;
            margin-left: 8px;


            &-text {
                color: #FFF;
                text-align: center;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: -0.5px;
            }
        }

        &-notificationtitlearea {
            display: flex;
            flex-direction: column;

            &-title {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &-input {
                .ant-input {
                    border: none;
                    border-radius: 100px;
                    padding: 12px 16px;
                    height: 56px;

                }

                ::placeholder {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    opacity: 0.5;
                }
            }

        }

        &-explanationarea {
            display: flex;
            flex-direction: column;

            &-title {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &-textarea {
                .ant-input {
                    border: none;
                    border-radius: 20px;
                    padding: 12px 16px;
                }

                ::placeholder {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    opacity: 0.5;
                }
            }
        }

        &-urlarea {
            display: flex;
            flex-direction: column;

            &-title {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &-url {
                .ant-input {
                    border: none;
                    border-radius: 20px;
                    padding: 12px 16px;
                    height: 56px;

                }

                ::placeholder {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    opacity: 0.5;
                }
            }
        }

        &-typearea {
            display: flex;
            flex-direction: column;

            &-title {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 8px;
            }

        }

        &-releasearea {
            display: flex;
            flex-direction: column;

            &-title {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 8px;
            }

            &-releasedate {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 20px;
                background: #FFF;
                padding: 12px 16px;
                width: fit-content;

                &-datearea {
                    display: flex;
                    flex-direction: column;

                    &-date {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    &-time {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                &-statusarea {
                    margin-left: 24px;
                }
            }
        }

        &-buttonarea {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;

            &-deletebutton {
                width: 158px;
                padding: 24px;
                border-radius: 50px;
                border: 1px solid rgba(0, 0, 0, 0.20);
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;

                &-icon {
                    color: #000;
                    font-size: 18px;
                    margin-left: 8px;
                }

                &-text {
                    color: #000;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }

            &-editbutton {
                width: 158px;
                padding: 24px;
                border-radius: 50px;
                background: #033677;
                display: flex;
                align-items: center;
                justify-content: center;

                &-icon {
                    color: #FFF;
                    font-size: 18px;
                    margin-left: 8px;
                }

                &-text {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }
        }

        .ant-card-head-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
        }

        .ant-card-bordered {
            border-radius: 50px;
            border: 1.5px solid rgba(0, 0, 0, 0.50);
            padding: 10px 0px;
            background: transparent;
            margin: 0px 8px;

            :first-child {
                margin-left: 0px;
            }

            :last-child {
                margin-right: 0px;
            }

        }

        .ant-card .ant-card-head {
            min-height: 0;
            border-bottom: none;
        }

        .ant-card-body {
            display: none;
        }

        .ant-form {
            width: 100%;
        }

        .ant-modal-content {
            width: auto;
            padding: 32px;
            border-radius: 30px;
            background: #EFEFEF;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }


        .ant-modal-content {
            padding: 32px;
            border-radius: 30px;
            background: #EFEFEF;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .ant-tabs-ink-bar {
            background: none;
        }

        .ant-tabs-nav-wrap {
            &::before {
                display: none;
            }
        }

        .ant-tabs-top>.ant-tabs-nav::before {
            border-bottom: 0px solid transparent;
        }

        .ant-tabs-tab {
            margin: 0 8px 0 0 !important;
            padding: 10px 16px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }

        .ant-modal-close {
            margin-top: 20px;
            margin-right: 20px;
            width: 36px;
            height: 36px;
            padding: 8px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }
    }

    .ant-table-thead {
        tr {
            th {
                &::before {
                    display: none !important;
                }
            }
        }
    }
}

.notification-modal-personalized-targetgroup-personsarea-select .ant-select-selector {
    padding: 12px 16px;
}