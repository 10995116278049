.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  background-color: #F2F2F2;

  .ant-badge .ant-badge-count {
    position: absolute;
    right: 20px;
    top: 5px;
  }


  &-left {
    width: 128px;
    padding: 17.5px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    &.active {
      width: 225px;
    }

    &-logo {
      display: flex;
      align-items: center;

      &-icon {
        width: 30px;
        height: 30px;
      }

      &-name {
        color: #000;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 5px;
      }
    }

    &-exit {

      &-icon {
        font-size: 24px;
      }
    }
  }

  &-right {
    padding: 12px 24px;
    width: calc(100% - 265px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      &-title {
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        span {
          color: #033677;
          font-family: "Poppins", sans-serif;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-right: 5px;
        }
      }
    }


    &-icons {
      display: flex;
      align-items: center;

      &-settings {
        font-size: 28px;
        margin-right: 16px;
      }

      &-notification {
        font-size: 28px;


        &-button {
          background-color: transparent;
          text-decoration: none;
          border: none;
          box-shadow: none;

          &:hover {
            background: transparent !important;
          }
        }
      }

      &-profile {
        width: 48px;
        height: 48px;
        border-radius: 50px;
        background: #D9D9D9;
        margin-left: 16px;
      }
    }
  }



  &-dropdown {
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.24);
    width: 380px;
    padding: 16px;
    position: absolute;
    top: 70px !important;
    z-index: 9999999999;

    .ant-dropdown-menu {
      box-shadow: none !important;
      padding: 0px;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-item-active {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    .ant-dropdown-arrow {
      right: 24px !important;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
      padding: 0px !important;
      cursor: default;
    }

    &-notification {

      &-content {
        display: flex;
        text-align: left;
        flex-direction: column;
        display: flex;
        padding: 16px;
        border-radius: 26px;
        background: #FAFAFA;
        margin-bottom: 8px;

        &-title {
          color: #000;
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-bottom: 4px;
        }

        &-desc {
          color: #000;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &-link {
          border-radius: 50px;
          background: rgba(3, 54, 119, 0.10);
          padding: 4px 12px;
          width: fit-content;
          margin-top: 8px;
          border: none;

          &-text {
            color: #033677;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &-new {
          position: absolute;
          right: 0px;
          width: 8px;
          height: 8px;
          border-radius: 50px;
          background: #FF3D00;
        }
      }

      &-newnotification {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        &-button {
          border: none;
          background-color: transparent;
          text-decoration: none;


          &-text {
            color: #033677;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: -0.5px;
            text-decoration-line: underline;
          }

          &-icon {
            font-size: 20px;
            color: #033677;
            margin-left: 4px;
          }
        }
      }
    }


  }



}