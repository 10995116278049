$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-lock: unquote('"\\e92b"');
$icon-email: unquote('"\\e92a"');
$icon-UserInfo: unquote('"\\e929"');
$icon-Plus: unquote('"\\e928"');
$icon-Flash: unquote('"\\e920"');
$icon-GasCan: unquote('"\\e921"');
$icon-Image: unquote('"\\e922"');
$icon-Star: unquote('"\\e923"');
$icon-Market: unquote('"\\e924"');
$icon-Toilet: unquote('"\\e925"');
$icon-Car-Wash: unquote('"\\e926"');
$icon-Mosque: unquote('"\\e927"');
$icon-Arrow-Up-N: unquote('"\\e91f"');
$icon-Office: unquote('"\\e91e"');
$icon-Location-1: unquote('"\\e91c"');
$icon-Check-Out: unquote('"\\e91b"');
$icon-Menu: unquote('"\\e91a"');
$icon-Location: unquote('"\\e919"');
$icon-More: unquote('"\\e918"');
$icon-Notification-Add: unquote('"\\e917"');
$icon-Notification: unquote('"\\e915"');
$icon-Setting: unquote('"\\e916"');
$icon-Menu-Close: unquote('"\\e902"');
$icon-Arrow-Left: unquote('"\\e903"');
$icon-Eye: unquote('"\\e904"');
$icon-Edit: unquote('"\\e905"');
$icon-Error: unquote('"\\e906"');
$icon-Delete: unquote('"\\e907"');
$icon-Calendar: unquote('"\\e908"');
$icon-Plate: unquote('"\\e909"');
$icon-Arrow-Down: unquote('"\\e90a"');
$icon-Arrow-Up: unquote('"\\e91d"');
$icon-Clock: unquote('"\\e90b"');
$icon-Calendar-2: unquote('"\\e90c"');
$icon-Close: unquote('"\\e90d"');
$icon-User: unquote('"\\e90e"');
$icon-Gas-Station: unquote('"\\e90f"');
$icon-Wallet: unquote('"\\e910"');
$icon-Story: unquote('"\\e911"');
$icon-Store: unquote('"\\e912"');
$icon-Notification-1: unquote('"\\e913"');
$icon-Home: unquote('"\\e914"');
$icon-Arrow-Right: unquote('"\\e901"');
$icon-Car: unquote('"\\e900"');

