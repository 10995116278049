.stationdetails {
    &-header {
        display: flex;
        align-items: center;
        padding: 32px;
        border-radius: 30px;
        background: #FFF;

        &-backbutton {
            padding: 8px;
            gap: 10px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            background-color: transparent;
            margin-right: 16px;
            cursor: pointer;

            &-icon {
                font-size: 24px;
                color: #000;
            }
        }

        &-prevpage {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            opacity: 0.5;
        }

        &-arrowright {
            font-size: 24px;
            color: #000;
            margin: 0px 8px;
        }

        &-title {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }
    }

    &-body {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 24px;

        &-leftarea {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 30px;
            background: #FFF;
            padding: 32px;
            margin-right: 24px;
            width: 348px;
            height: max-content;

            &-info {
                display: flex;
                align-items: center;
                flex-direction: column;
                border-bottom: solid 1px rgba(0, 0, 0, 0.10);

                &-image {
                    height: 208px;
                    flex-shrink: 0;
                    border-radius: 30px;
                    position: relative;
                    background-color: #3699FF;
                    margin-bottom: 24px;

                    &-button {
                        display: flex;
                        align-items: center;
                        position: relative;
                        top: -70px;
                        right: -110px;
                        border-radius: 50px;
                        background: #F3F6F9;
                        border: none;
                        cursor: pointer;
                        padding: 8px;


                        &-icon {
                            font-size: 18px;
                            margin-right: 10px;
                            color: #3699FF;
                        }

                        &-text {
                            color: #3699FF;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                        }
                    }
                }

                &-namearea {
                    display: flex;
                    align-items: center;
                    margin-bottom: 32px;


                    &-stationname {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;

                    }

                    &-editbutton {
                        font-size: 18px;
                        color: #3699FF;
                        margin-left: 10px;
                    }
                }



            }

            &-contact {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;


                &-phonenumber {
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    width: 100%;
                    margin: 20px 0px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                    }

                    &-number {
                        color: rgba(0, 0, 0, 0.50);
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        margin: 4px 0px;
                    }

                    &-button {
                        display: flex;
                        align-items: center;
                        border-radius: 50px;
                        background: #F3F6F9;
                        padding: 8px;
                        cursor: pointer;
                        border: none;
                        width: fit-content;

                        &-icon {
                            font-size: 18px;
                            margin-right: 10px;
                            color: #3699FF;
                        }

                        &-text {
                            color: #3699FF;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                        }

                    }
                }

                &-location {
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    width: 100%;
                    margin-bottom: 20px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                    }

                    &-address {
                        color: rgba(0, 0, 0, 0.50);
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        margin: 4px 0px;
                    }

                    &-button {
                        display: flex;
                        align-items: center;
                        border-radius: 50px;
                        background: #F3F6F9;
                        padding: 8px;
                        cursor: pointer;
                        border: none;
                        width: fit-content;

                        &-icon {
                            font-size: 18px;
                            color: #3699FF;
                            margin-right: 10px;
                        }

                        &-text {
                            color: #3699FF;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                        }

                    }
                }

                &-lastedit {
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    width: 100%;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                    }

                    &-date {
                        color: rgba(0, 0, 0, 0.50);
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        margin: 4px 0px;
                    }

                }
            }

        }

        &-rightarea {
            display: flex;
            align-items: start;
            flex-wrap: wrap;
            width: calc(100% - 350px);

            &-priceservice {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                border-radius: 30px;
                background: #FFF;
                padding: 32px;
                width: 100%;

                &-pricearea {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    border-bottom: solid 1px rgba(0, 0, 0, 0.10);

                    &-titlearea {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 15px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        &-button {
                            display: flex;
                            align-items: center;
                            border-radius: 50px;
                            background: #033677;
                            padding: 10px 16px;
                            cursor: pointer;

                            &-icon {
                                font-size: 24px;
                                color: #FFF;
                                margin-right: 8px;
                            }

                            &-text {
                                color: #FFF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    &-fuelarea {
                        display: flex;
                        align-items: stretch;
                        width: 100%;
                        padding-bottom: 30px;


                        &-fuel {
                            display: flex;
                            flex-direction: column;
                            border-radius: 20px;
                            background: radial-gradient(168.7% 141.42% at 0% 0%, #23A718 0%, #165C2A 100%);
                            padding: 16px;
                            width: 140px;

                            &-price {
                                color: rgba(255, 255, 255, 0.90);
                                font-family: "Poppins", sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px;
                            }

                            &-textarea {
                                display: flex;
                                align-items: end;
                                justify-content: space-between;
                                margin-top: 5px;


                                &-text {
                                    display: flex;
                                    flex-direction: column;

                                    &-title {
                                        color: rgba(255, 255, 255, 0.80);
                                        font-family: "Poppins", sans-serif;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 16px;
                                    }

                                    &-desc {
                                        color: rgba(255, 255, 255, 0.50);
                                        font-family: "Poppins", sans-serif;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px;
                                    }
                                }

                                &-icon {
                                    font-size: 24px;
                                    color: rgba(255, 255, 255, 0.90);
                                    opacity: 1;
                                }
                            }
                        }

                        &-electric {
                            display: flex;
                            flex-direction: column;
                            border-radius: 20px;
                            background: radial-gradient(168.7% 141.42% at 0% 0%, #2A8FAF 0%, #16365C 100%);
                            padding: 16px;
                            width: 140px;
                            margin: 0px 8px;

                            &-price {
                                color: rgba(255, 255, 255, 0.90);
                                font-family: "Poppins", sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px;
                            }

                            &-textarea {
                                display: flex;
                                align-items: end;
                                justify-content: space-between;
                                margin-top: 5px;


                                &-text {
                                    display: flex;
                                    flex-direction: column;

                                    &-title {
                                        color: rgba(255, 255, 255, 0.80);
                                        font-family: "Poppins", sans-serif;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 16px;
                                    }

                                    &-desc {
                                        color: rgba(255, 255, 255, 0.50);
                                        font-family: "Poppins", sans-serif;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px;
                                    }
                                }

                                &-icon {
                                    font-size: 24px;
                                    color: rgba(255, 255, 255, 0.90);
                                    opacity: 1;
                                }
                            }
                        }

                        &-diesel {
                            display: flex;
                            flex-direction: column;
                            border-radius: 20px;
                            background: radial-gradient(168.7% 141.42% at 0% 0%, #747474 0%, #1B1B1B 100%);
                            padding: 16px;
                            width: 140px;

                            &-price {
                                color: rgba(255, 255, 255, 0.90);
                                font-family: "Poppins", sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px;
                            }

                            &-textarea {
                                display: flex;
                                align-items: end;
                                justify-content: space-between;
                                margin-top: 5px;


                                &-text {
                                    display: flex;
                                    flex-direction: column;

                                    &-title {
                                        color: rgba(255, 255, 255, 0.80);
                                        font-family: "Poppins", sans-serif;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 16px;
                                    }

                                    &-desc {
                                        color: rgba(255, 255, 255, 0.50);
                                        font-family: "Poppins", sans-serif;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px;
                                    }
                                }

                                &-icon {
                                    font-size: 24px;
                                    color: rgba(255, 255, 255, 0.90);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                &-servicearea {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 30px;

                    &-titlearea {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 15px;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        &-button {
                            display: flex;
                            align-items: center;
                            border-radius: 50px;
                            background: #033677;
                            padding: 10px 16px;
                            cursor: pointer;

                            &-icon {
                                font-size: 24px;
                                color: #FFF;
                                margin-right: 8px;
                            }

                            &-text {
                                color: #FFF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    &-services {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                        align-self: stretch;

                        &-style {
                            display: flex;
                            align-items: center;
                            border-radius: 50px;
                            background: #FAFAFA;
                            padding: 8px 12px;

                            &-icon {
                                font-size: 24px;
                                color: #000;
                                margin-right: 4px;
                            }

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }


                }
            }

            &-commentrating {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                border-radius: 30px;
                background: #FFF;
                padding: 32px;
                width: 100%;
                margin-top: 24px;

                &-titlearea {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 15px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &-star {
                        display: flex;
                        align-items: center;


                        &-icon {
                            font-size: 24px;
                            color: #FFB800;

                        }

                        &-text {
                            color: #FFB800;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

                &-ratearea {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-radius: 30px;
                    background: #FAFAFA;
                    padding: 16px;
                    margin: 16px 0px;
                    width: -webkit-fill-available;

                    .anticon {
                        svg {

                            stroke: black !important;
                            stroke-width: 40px !important;
                        }
                    }

                    .ant-tabs-nav-operations {
                        visibility: hidden !important;
                        display: none !important;
                    }

                    .ant-rate-star-second {
                        color: transparent;
                    }

                    .ant-rate {
                        color: #033677;
                        font-size: 18px;
                    }

                    .ant-rate-star {
                        margin-inline-end: 3px !important;
                    }

                    .ant-flex-gap-large {
                        gap: 11px !important
                    }

                    &-average {
                        display: flex;
                        flex-direction: column;
                        justify-content: left;
                        width: 50%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            margin-bottom: 8px;
                        }

                        &-countarea {
                            display: flex;
                            align-items: end;
                            justify-content: space-between;
                            width: 100px;



                            &-rate {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;

                            }

                            &-count {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                        }
                    }


                    &-distribution {
                        display: flex;
                        flex-direction: column;
                        justify-content: left;
                        width: 50%;

                        &-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            margin-bottom: 8px;
                        }

                        &-flex {
                            display: flex;
                            align-items: center;
                            justify-content: left;



                            &-number {
                                color: #000;
                                text-align: right;
                                font-family: "Poppins", sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                            }

                            &-line {
                                margin: 0px 8px;
                                width: 100% !important;



                                .ant-progress,
                                .ant-progress-outer {
                                    max-width: 500px;
                                }

                                .ant-progress-bg {
                                    background: #033677;
                                }

                                .ant-progress-inner {
                                    border-radius: 50px;
                                    background: rgba(3, 54, 119, 0.10);
                                }

                                .ant-progress-line {
                                    width: fit-content !important;
                                }
                            }

                            &-count {
                                color: #000;
                                text-align: left;
                                font-family: "Poppins", sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                            }




                        }

                    }
                }

                &-stroke {
                    border-bottom: solid 10px rgba(0, 0, 0, 0.10);
                }

                &-commentarea {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 16px 0;

                        &-text {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }

                    &-comment {
                        display: flex;
                        flex-direction: column;
                        padding: 16px;
                        border-radius: 30px;
                        background: #FAFAFA;
                        margin-top: 8px;

                        .anticon {
                            svg {

                                stroke: black !important;
                                stroke-width: 40px !important;
                            }
                        }

                        &-profilearea {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;


                            &-profile {
                                display: flex;
                                align-items: center;

                                &-photo {
                                    border-radius: 50px;
                                    background: #D9D9D9;
                                    width: 28px;
                                    height: 28px;
                                    margin-right: 14px;
                                }

                                &-name {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            &-moreicon {
                                font-size: 24px;
                                color: #000;
                                background: transparent;
                                border: none;
                                cursor: pointer;
                            }
                        }

                        &-average {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            margin: 8px 0px;
                            width: 100%;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-right: 8px;
                            }

                            .ant-rate {
                                color: #033677;
                                font-size: 18px;
                            }

                            .ant-rate-star {
                                margin-inline-end: 3px !important;
                            }

                            &-date {
                                color: rgba(0, 0, 0, 0.50);
                                font-family: "Poppins", sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                // margin-left: 8px;
                            }
                        }

                        &-comments {
                            display: flex;
                            align-items: center;
                            width: calc(100% - 32px);
                            margin-bottom: 8px;

                            &-comment {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 8px 0px;
                                width: 100%;

                                :first-child {
                                    margin-top: 0px;
                                }

                                :last-child {
                                    margin-bottom: 0px;
                                }
                            }

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }

                        &-morebutton {
                            display: flex;
                            align-items: center;
                            text-align: center;
                            justify-content: left;
                            margin-top: 30px;

                            &-button {
                                text-decoration: none;
                                border: none;
                                background-color: transparent;
                                cursor: pointer;

                                &-text {
                                    color: #033677;
                                    text-align: center;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 14px;
                                    letter-spacing: -0.5px;
                                    text-decoration-line: underline;
                                }
                            }
                        }
                    }
                }
            }



        }
    }

    &-popover {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;


        &-removecomment {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            background-color: transparent;
            cursor: pointer;
            border: none;

            &-icon {
                color: var(--UI-System-Red, #F64E60);
                font-size: 24px;
                margin-right: 8px;
            }

            &-text {
                color: var(--UI-System-Red, #F64E60);
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        &-showuser {
            display: flex;
            align-items: center;
            background-color: transparent;
            cursor: pointer;
            border: none;
            padding: 0px;

            &-icon {
                color: var(--UI-System-Blue, #3699FF);
                font-size: 24px;
                margin-right: 8px;
            }

            &-text {
                color: var(--UI-System-Blue, #3699FF);
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .ant-popover-inner {
            border-radius: 50px !important;

        }
    }


}