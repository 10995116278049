.users {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 30px;
    background: #FFF;
    height: calc(100% - 145px);

    &-header {
        display: flex;
        justify-content: space-between;

        &-title {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }
    }

    &-table {
        margin-top: 32px;


        .ant-table-tbody {
            tr {
                td {
                    width: 22%;
                    padding: 0px;
                }
            }
        }

        .ant-table-cell {
            margin: 0px;
            display: table-cell;
            vertical-align: middle;
        }

        .ant-table-thead {
            tr {
                th {
                    color: #B5B5C3;
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.39px;
                    background-color: transparent;
                    padding: 0px 0px 16px 0px;
                }
            }
        }

        .ant-table-column-sorters {
            justify-content: left;
        }

        .ant-table-column-title {
            flex: none;
        }


        &-users {
            display: flex;
            align-items: center;
            margin: 16px 0px;
            ;

            &-profilephoto {
                border-radius: 50px;
                background: #D9D9D9;
                width: 44px;
                height: 44px;
                margin-right: 14px;
            }

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-email {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }
        }

        &-date {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-title {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.39px;
            }

            &-date {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }

            &-time {
                color: #B5B5C3;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 4px;
            }
        }

        &-age {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-cars {
            padding: 5px 9px;
            border-radius: 6px;
            background: #F2F2F2;
            border: none;
            cursor: pointer;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        &-options {

            &-edit {
                display: flex;
                font-size: 18px;
                color: #3699FF;
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
                border: none;
                cursor: pointer;
            }

            &-view {
                display: flex;
                font-size: 18px;
                color: #3699FF;
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
                border: none;
                cursor: pointer;
            }

            &-block {
                display: flex;
                font-size: 18px;
                color: #F64E60;
                padding: 8px;
                border-radius: 50px;
                background: #FFE2E5;
                border: none;
                cursor: pointer;
            }

            &-delete {
                display: flex;
                font-size: 18px;
                color: #3699FF;
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
                border: none;
                cursor: pointer;
            }
        }
    }

    .anticon-caret-up {
        display: none;
    }

    .anticon-caret-down {
        font-size: 20px;
        color: #3699FF;
    }


    .ant-tag-cyan {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #C9F7F5;
        color: #1BC5BD;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .ant-tag-yellow {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #FFF4DE;
        color: #FFA800;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .ant-tag-red {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #FFE2E5;
        color: #F64E60;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .users-pagination {
        display: flex;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-label {
        margin-right: 16px;
        margin-top: 16px;
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ant-pagination-prev {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-next {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-item {
        border: none;
        background-color: transparent;
        min-width: 24px;

        a {
            color: #000;
            font-weight: 100;
            opacity: 0.3;
        }

    }

    .ant-pagination-item-active {

        a {
            color: #000;
            font-weight: bold;
            opacity: 1;
        }
    }

    .ant-table-thead {
        tr {
            th {
                &::before {
                    display: none !important;
                }
            }
        }
    }
}