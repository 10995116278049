@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?onpema');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?onpema#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?onpema') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?onpema') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?onpema##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-UserInfo {
  &:before {
    content: $icon-UserInfo; 
  }
}
.icon-Plus {
  &:before {
    content: $icon-Plus; 
  }
}
.icon-Flash {
  &:before {
    content: $icon-Flash; 
  }
}
.icon-GasCan {
  &:before {
    content: $icon-GasCan; 
  }
}
.icon-Image {
  &:before {
    content: $icon-Image; 
  }
}
.icon-Star {
  &:before {
    content: $icon-Star; 
  }
}
.icon-Market {
  &:before {
    content: $icon-Market; 
  }
}
.icon-Toilet {
  &:before {
    content: $icon-Toilet; 
  }
}
.icon-Car-Wash {
  &:before {
    content: $icon-Car-Wash; 
  }
}
.icon-Mosque {
  &:before {
    content: $icon-Mosque; 
  }
}
.icon-Arrow-Up-N {
  &:before {
    content: $icon-Arrow-Up-N; 
  }
}
.icon-Office {
  &:before {
    content: $icon-Office; 
  }
}
.icon-Location-1 {
  &:before {
    content: $icon-Location-1; 
  }
}
.icon-Check-Out {
  &:before {
    content: $icon-Check-Out; 
  }
}
.icon-Menu {
  &:before {
    content: $icon-Menu; 
  }
}
.icon-Location {
  &:before {
    content: $icon-Location; 
  }
}
.icon-More {
  &:before {
    content: $icon-More; 
  }
}
.icon-Notification-Add {
  &:before {
    content: $icon-Notification-Add; 
  }
}
.icon-Notification {
  &:before {
    content: $icon-Notification; 
  }
}
.icon-Setting {
  &:before {
    content: $icon-Setting; 
  }
}
.icon-Menu-Close {
  &:before {
    content: $icon-Menu-Close; 
  }
}
.icon-Arrow-Left {
  &:before {
    content: $icon-Arrow-Left; 
  }
}
.icon-Eye {
  &:before {
    content: $icon-Eye; 
  }
}
.icon-Edit {
  &:before {
    content: $icon-Edit; 
  }
}
.icon-Error {
  &:before {
    content: $icon-Error; 
  }
}
.icon-Delete {
  &:before {
    content: $icon-Delete; 
  }
}
.icon-Calendar {
  &:before {
    content: $icon-Calendar; 
  }
}
.icon-Plate {
  &:before {
    content: $icon-Plate; 
  }
}
.icon-Arrow-Down {
  &:before {
    content: $icon-Arrow-Down; 
  }
}
.icon-Arrow-Up {
  &:before {
    content: $icon-Arrow-Up; 
  }
}
.icon-Clock {
  &:before {
    content: $icon-Clock; 
  }
}
.icon-Calendar-2 {
  &:before {
    content: $icon-Calendar-2; 
  }
}
.icon-Close {
  &:before {
    content: $icon-Close; 
  }
}
.icon-User {
  &:before {
    content: $icon-User; 
  }
}
.icon-Gas-Station {
  &:before {
    content: $icon-Gas-Station; 
  }
}
.icon-Wallet {
  &:before {
    content: $icon-Wallet; 
  }
}
.icon-Story {
  &:before {
    content: $icon-Story; 
  }
}
.icon-Store {
  &:before {
    content: $icon-Store; 
  }
}
.icon-Notification-1 {
  &:before {
    content: $icon-Notification-1; 
  }
}
.icon-Home {
  &:before {
    content: $icon-Home; 
  }
}
.icon-Arrow-Right {
  &:before {
    content: $icon-Arrow-Right; 
  }
}
.icon-Car {
  &:before {
    content: $icon-Car; 
  }
}

