.userdetails {

    &-header {
        display: flex;
        align-items: center;
        padding: 32px;
        border-radius: 30px;
        background: #FFF;

        &-backbutton {
            padding: 8px;
            gap: 10px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            background-color: transparent;
            margin-right: 16px;
            cursor: pointer;

            &-icon {
                font-size: 24px;
                color: #000;
            }
        }

        &-prevpage {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            opacity: 0.5;
        }

        &-arrowright {
            font-size: 24px;
            color: #000;
            margin: 0px 8px;
        }

        &-title {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }
    }

    &-body {
        display: flex;
        flex-direction: row;
        margin-top: 24px;

        &-leftarea {
            display: inline-flex;
            padding: 32px;
            flex-direction: column;
            align-items: center;
            border-radius: 30px;
            background: #FFF;
            margin-right: 24px;
            min-width: 300px;
            height: max-content;

            &-profilarea {
                display: flex;
                flex-direction: column;
                align-items: center;

                &-photo {
                    width: 128px;
                    height: 128px;
                    border-radius: 90px;
                    background: #D9D9D9;
                    margin-bottom: 24px;
                }

                &-namesurname {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 21px;
                    margin-bottom: 48px;
                }
            }

            &-detailarea {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                border-bottom: solid 1px rgba(0, 0, 0, 0.10);
                padding-bottom: 20px;
                margin-bottom: 20px;


                &-detail {
                    display: flex;
                    align-items: center;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;

                    &-text {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                        margin-right: 8px;
                    }

                    &-icon {
                        font-size: 24px;
                        color: #000;

                    }
                }

                &-button {
                    border-radius: 50px;
                    background: #F3F6F9;
                    padding: 8px;
                    display: flex;
                    cursor: pointer;
                    border: none;

                    &-icon {
                        font-size: 18px;
                        color: #3699FF;
                        margin-right: 10px;
                    }

                    &-text {
                        color: #3699FF;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }
            }

            &-accont {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 100%;
                margin: 10px 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                }

                &-text {
                    color: rgba(0, 0, 0, 0.50);
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            &-email {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 100%;
                margin: 10px 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                }

                &-text {
                    color: rgba(0, 0, 0, 0.50);
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            &-vehicles {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 100%;
                margin: 10px 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                }

                &-text {
                    color: rgba(0, 0, 0, 0.50);
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }

                &-button {
                    border-radius: 50px;
                    background: #F3F6F9;
                    padding: 8px;
                    display: flex;
                    cursor: pointer;
                    border: none;
                    width: fit-content;
                    align-items: center;

                    &-icon {
                        font-size: 18px;
                        color: #3699FF;
                        margin-right: 10px;
                    }

                    &-text {
                        color: #3699FF;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }
            }

            &-comment {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 100%;
                margin: 10px 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                }

                &-text {
                    color: rgba(0, 0, 0, 0.50);
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }

                &-button {
                    border-radius: 50px;
                    background: #F3F6F9;
                    padding: 8px;
                    display: flex;
                    cursor: pointer;
                    border: none;
                    width: fit-content;
                    align-items: center;

                    &-icon {
                        font-size: 18px;
                        color: #3699FF;
                        margin-right: 10px;
                    }

                    &-text {
                        color: #3699FF;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }
            }

            &-finalentry {
                display: flex;
                flex-direction: column;
                justify-content: left;
                width: 100%;
                margin: 10px 0px;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                }

                &-text {
                    color: rgba(0, 0, 0, 0.50);
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }





        }


        &-rightarea {

            width: calc(100% - 450px);

            .ant-tabs-tab-active {
                border-radius: 50px;
                background: #000;
                padding: 10px 16px;
                color: #FFF;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                .ant-tabs-tab-btn {
                    color: #FFF !important;
                }
            }

            .ant-tabs-ink-bar {
                background: none;
            }

            .ant-tabs-nav-wrap {
                &::before {
                    display: none;
                }
            }



            .ant-tabs-tab {
                margin: 0 0 0 8px !important;
                padding: 10px 16px;
                border-radius: 50px;
                border: 1px solid rgba(0, 0, 0, 0.20);
            }

            &-overview {

                &-commentsratings {
                    display: flex;
                    flex-direction: column;
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    width: 100%;
                    margin-bottom: 24px;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;

                    }

                    &-textfilter {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 8px;
                        padding-bottom: 20px;
                        border-bottom: solid 1px rgba(0, 0, 0, 0.10);

                        .ant-select-selector {
                            border: none !important;
                            display: flex;
                            align-items: center;
                        }



                        &-text {
                            color: rgba(0, 0, 0, 0.50);
                            font-family: "Poppins", sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                        }

                        &-filter {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            span {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif !important;
                                font-size: 13px !important;
                                font-style: normal !important;
                                font-weight: 600 !important;
                                line-height: normal !important;
                                letter-spacing: 0.39px !important;

                            }

                            .ant-select-arrow {
                                color: #3699FF;
                                font-size: 18px;
                                height: 17px;

                            }


                            &-text {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                                margin-right: 8px;
                            }
                        }

                    }


                    &-ratingarea {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        padding: 16px;
                        border-radius: 30px;
                        background: #FAFAFA;
                        margin-top: 20px;

                        &-profilearea {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;


                            &-profile {
                                display: flex;
                                align-items: center;

                                &-photo {
                                    border-radius: 50px;
                                    background: #D9D9D9;
                                    width: 28px;
                                    height: 28px;
                                    margin-right: 14px;
                                }

                                &-name {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            &-moreicon {
                                font-size: 24px;
                                color: #000;
                                background: transparent;
                                border: none;
                                cursor: pointer;
                            }
                        }

                        &-average {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            margin: 8px 0px;
                            width: 100%;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-right: 8px;
                            }

                            .ant-rate {
                                color: #033677;
                                font-size: 18px;
                            }

                            .ant-rate-star {
                                margin-inline-end: 3px !important;
                            }

                            &-date {
                                color: rgba(0, 0, 0, 0.50);
                                font-family: "Poppins", sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-left: 8px;
                            }
                        }

                        &-ratings {
                            border-radius: 20px;
                            background: #EEE;
                            padding: 16px;
                            display: flex;
                            align-items: center;
                            width: calc(100% - 32px);
                            flex-direction: column;
                            margin-bottom: 8px;



                            &-rate {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 8px 0px;
                                width: 100%;

                                :first-child {
                                    margin-top: 0px;
                                }

                                :last-child {
                                    margin-bottom: 0px;
                                }
                            }

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;


                            }

                            &-location {
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                width: 100%;

                                &-icon {
                                    color: #033677;
                                    font-size: 20px;
                                    margin-right: 4px;
                                }

                                &-text {
                                    color: #033677;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                            }
                        }

                        .anticon {
                            svg {

                                stroke: black !important;
                                /* Yıldızın sınırlarını Aqua yapar */
                                stroke-width: 40px !important;
                                /* Sınır çizgisinin kalınlığı */
                            }
                        }

                        .ant-tabs-nav-operations {
                            visibility: hidden !important;
                            display: none !important;
                        }

                        .ant-rate-star-second {
                            color: transparent;
                        }
                    }

                    &-commentarea {
                        display: flex;
                        flex-direction: column;
                        padding: 16px;
                        border-radius: 30px;
                        background: #FAFAFA;
                        margin-top: 20px;

                        .anticon {
                            svg {

                                stroke: black !important;
                                /* Yıldızın sınırlarını Aqua yapar */
                                stroke-width: 40px !important;
                                /* Sınır çizgisinin kalınlığı */
                            }
                        }

                        &-profilearea {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;


                            &-profile {
                                display: flex;
                                align-items: center;

                                &-photo {
                                    border-radius: 50px;
                                    background: #D9D9D9;
                                    width: 28px;
                                    height: 28px;
                                    margin-right: 14px;
                                }

                                &-name {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            &-moreicon {
                                font-size: 24px;
                                color: #000;
                                background: transparent;
                                border: none;
                                cursor: pointer;
                            }
                        }

                        &-average {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            margin: 8px 0px;
                            width: 100%;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-right: 8px;
                            }

                            .ant-rate {
                                color: #033677;
                                font-size: 18px;
                            }

                            .ant-rate-star {
                                margin-inline-end: 3px !important;
                            }

                            &-date {
                                color: rgba(0, 0, 0, 0.50);
                                font-family: "Poppins", sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                // margin-left: 8px;
                            }
                        }

                        &-comments {
                            display: flex;
                            align-items: center;
                            width: calc(100% - 32px);
                            margin-bottom: 8px;

                            &-comment {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 8px 0px;
                                width: 100%;

                                :first-child {
                                    margin-top: 0px;
                                }

                                :last-child {
                                    margin-bottom: 0px;
                                }
                            }

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }


                            &-location {
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                width: 100%;
                                margin-top: 8px;

                                &-icon {
                                    color: #033677;
                                    font-size: 20px;
                                    margin-right: 4px;
                                }

                                &-text {
                                    color: #033677;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                            }
                        }



                    }

                    &-more {
                        display: flex;
                        width: 100&;
                        margin-top: 20px;


                        &-text {
                            color: #3699FF;
                            font-family: "Poppins", sans-serif;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: 0.39px;
                            text-decoration-line: underline;
                            border: none;
                            background: transparent;
                            cursor: pointer;
                        }
                    }
                }


                &-cars {
                    display: flex;
                    flex-direction: column;
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    margin-bottom: 24px;
                    width: 100%;

                    &-title {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;

                    }

                    &-textfilter {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 8px;
                        padding-bottom: 20px;
                        border-bottom: solid 1px rgba(0, 0, 0, 0.10);

                        .ant-select-selector {
                            border: none !important;
                            display: flex;
                            align-items: center;
                        }



                        &-text {
                            color: rgba(0, 0, 0, 0.50);
                            font-family: "Poppins", sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                        }

                        &-filter {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            span {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif !important;
                                font-size: 13px !important;
                                font-style: normal !important;
                                font-weight: 600 !important;
                                line-height: normal !important;
                                letter-spacing: 0.39px !important;

                            }

                            .ant-select-arrow {
                                color: #3699FF;
                                font-size: 18px;
                                height: 17px;

                            }


                            &-text {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                                margin-right: 8px;
                            }
                        }

                    }

                    &-carsarea {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 100%;
                        gap: 10px;
                        margin-top: 20px;

                        &-car {
                            display: flex;
                            flex-direction: row;
                            padding: 24px 20px;
                            border-radius: 30px;
                            background: #FAFAFA;


                            &-carinfo {
                                margin-right: 10px;


                                &-title {
                                    display: flex;
                                    align-items: center;

                                    &-icon {
                                        font-size: 24px;
                                        color: #033677;
                                        margin-right: 4px;
                                    }

                                    &-carname {
                                        color: #033677;
                                        font-family: "Poppins", sans-serif;
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 14px;
                                        letter-spacing: -0.5px;
                                    }
                                }

                                &-info {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 8px;

                                    &-fuel {
                                        display: flex;
                                        align-items: center;
                                        margin-right: 16px;

                                        &-icon {
                                            font-size: 24px;
                                            color: #000;
                                            margin-right: 4px;
                                        }

                                        &-text {
                                            color: rgba(0, 0, 0, 0.70);
                                            font-family: "Poppins", sans-serif;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: -0.5px;
                                        }
                                    }

                                    &-date {
                                        display: flex;
                                        align-items: center;
                                        margin-right: 16px;

                                        &-icon {
                                            font-size: 24px;
                                            color: #000;
                                        }

                                        &-text {
                                            color: rgba(0, 0, 0, 0.70);
                                            font-family: "Poppins", sans-serif;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: -0.5px;
                                        }
                                    }

                                    &-plate {
                                        display: flex;
                                        align-items: center;

                                        &-icon {
                                            font-size: 24px;
                                            color: #000;
                                            margin-right: 4px;
                                        }

                                        &-text {
                                            color: rgba(0, 0, 0, 0.70);
                                            font-family: "Poppins", sans-serif;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: -0.5px;
                                        }
                                    }
                                }

                            }

                            &-eye {
                                display: flex;
                                align-items: start;
                                border: none;
                                background-color: transparent;
                                justify-content: left;



                                &-icon {
                                    font-size: 18px;
                                    color: #3699FF;
                                    border-radius: 50px;
                                    background: #F3F6F9;
                                    padding: 8px;
                                    cursor: pointer;
                                }

                            }
                        }


                    }
                }

                .ant-rate {
                    color: #033677;
                    font-size: 18px;
                }

                .ant-rate-star {
                    margin-inline-end: 3px !important;
                }


            }

            &-security {
                &-profile {
                    display: flex;
                    flex-direction: column;
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    width: 100%;
                    margin-bottom: 24px;

                    &-title {
                        border-bottom: solid 1px rgba(0, 0, 0, 0.10);
                        padding-bottom: 20px;

                        &-text {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 21px;
                        }
                    }

                    &-security {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;



                        &-email {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            margin-right: 16px;

                            .ant-input {
                                border-radius: 100px;
                                background: #F1F1F1;
                                padding: 12px 16px;
                                flex: 1 0 0;
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                border: none;
                                width: 100%;
                            }


                            &-title {
                                margin-bottom: 8px;

                                &-text {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                            }

                            &-inputbutton {
                                display: flex;
                                align-items: center;
                                width: 100%;

                                &-input {
                                    border-radius: 100px;
                                    background: #F1F1F1;
                                    padding: 12px 16px;
                                }

                                &-button {
                                    border-radius: 50px;
                                    background: #F3F6F9;
                                    padding: 8px;
                                    border: none;
                                    margin-left: 8px;


                                    &-icon {
                                        font-size: 18px;
                                        color: #3699FF;
                                        margin-right: 10px;
                                    }

                                    &-text {
                                        color: #3699FF;
                                        font-family: "Poppins", sans-serif;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }
                                }
                            }
                        }

                        &-password {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .ant-input {
                                border-radius: 100px;
                                background: #F1F1F1;
                                padding: 12px 16px;
                                flex: 1 0 0;
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                border: none;
                                width: 100%;
                            }


                            &-title {
                                margin-bottom: 8px;

                                &-text {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;

                                }
                            }

                            &-inputbutton {
                                display: flex;
                                align-items: center;
                                width: 100%;

                                &-input {
                                    border-radius: 100px;
                                    background: #F1F1F1;
                                    padding: 12px 16px;
                                }

                                &-button {
                                    border-radius: 50px;
                                    background: #F3F6F9;
                                    padding: 8px;
                                    border: none;
                                    margin-left: 8px;


                                    &-icon {
                                        font-size: 18px;
                                        color: #3699FF;
                                        margin-right: 10px;
                                    }

                                    &-text {
                                        color: #3699FF;
                                        font-family: "Poppins", sans-serif;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }
                                }
                            }
                        }
                    }

                }

                &-accounts {
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    width: 100%;


                    &-title {
                        display: flex;
                        flex-direction: column;
                        border-bottom: solid 1px rgba(0, 0, 0, 0.10);

                        &-text {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 21px;
                        }

                        &-subtext {
                            color: rgba(0, 0, 0, 0.50);
                            font-family: "Poppins", sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            margin-top: 8px;
                            margin-bottom: 20px;
                        }
                    }

                    &-linkedaccounts {
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;

                        &-account {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;


                            &-title {
                                display: flex;
                                align-items: center;

                                &-logo {
                                    width: 48px;
                                    height: 48px;
                                }

                                &-name {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 21px;
                                    margin-left: 8px;
                                }
                            }

                            &-connection {
                                display: flex;
                                align-items: center;

                                &-text {
                                    color: #000;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 21px;
                                }

                                &-subtext {
                                    color: rgba(0, 0, 0, 0.50);
                                    font-family: "Poppins", sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 21px;
                                    margin-left: 8px;
                                    margin-right: 16px;
                                }
                            }

                        }
                    }

                }



            }

            &-eventhistory {

                &-loginsessions {
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    width: 100%;

                    &-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-text {
                            color: #212121;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 21px;
                        }

                        &-button {
                            border-radius: 50px;
                            background: #033677;
                            padding: 10px 16px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            &-icon {
                                font-size: 24px;
                                color: #FFF;
                                margin-right: 8px;
                            }

                            &-text {
                                color: #FFF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    &-table {
                        margin-top: 32px;


                        .ant-table-tbody {
                            tr {
                                td {
                                    width: 25%;
                                    padding: 0px;
                                }
                            }
                        }

                        .ant-table-cell {
                            margin: 0px;
                            display: table-cell;
                            vertical-align: middle;

                        }

                        .ant-table-thead {
                            tr {
                                th {
                                    color: #B5B5C3;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    letter-spacing: 0.39px;
                                    background-color: transparent;
                                    padding: 0px 0px 16px 0px;
                                }
                            }
                        }

                        .ant-table-column-sorters {
                            justify-content: left;
                        }

                        .ant-table-column-title {
                            flex: none;
                        }

                        &-location {
                            display: flex;
                            align-items: center;
                            margin: 32px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-device {
                            display: flex;
                            align-items: center;
                            margin: 16px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-date {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;

                            &-title {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                            }

                            &-date {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;

                            }

                            &-time {
                                color: #B5B5C3;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                margin-top: 4px;
                            }
                        }

                        &-ipaddress {
                            display: flex;
                            align-items: center;
                            margin: 16px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-options {

                            &-edit {
                                font-size: 18px;
                                color: #3699FF;
                                padding: 8px;
                                border-radius: 50px;
                                background: #F3F6F9;
                            }

                            &-delete {
                                font-size: 18px;
                                color: #3699FF;
                                padding: 8px;
                                border-radius: 50px;
                                background: #F3F6F9;
                            }
                        }

                        &-footerbutton {
                            border: none;
                            background: transparent;
                            margin-top: 32px;

                            &-text {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                                text-decoration-line: underline;
                            }
                        }

                        .anticon-caret-up {
                            display: none;
                        }

                        .anticon-caret-down {
                            font-size: 20px;
                            color: #3699FF;
                        }


                        .ant-tag-cyan {
                            padding: 5px 9px;
                            border: none;
                            border-radius: 6px;
                            background: #C9F7F5;
                            color: #1BC5BD;
                            font-family: "Poppins", sans-serif;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            width: 92px;
                            text-align: center;
                        }

                        .ant-tag-yellow {
                            padding: 5px 9px;
                            border: none;
                            border-radius: 6px;
                            background: #FFF4DE;
                            color: #FFA800;
                            font-family: "Poppins", sans-serif;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            width: 92px;
                            text-align: center;
                        }

                        .ant-tag-red {
                            padding: 5px 9px;
                            border: none;
                            border-radius: 6px;
                            background: #FFE2E5;
                            color: #F64E60;
                            font-family: "Poppins", sans-serif;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            width: 92px;
                            text-align: center;
                        }

                        .notification-pagination {
                            display: flex;
                            justify-content: right;
                            margin-top: 16px;
                        }

                        .pagination-container {
                            display: flex;
                            align-items: center;
                            justify-content: right;
                            margin-top: 16px;
                        }

                        .pagination-label {
                            margin-right: 16px;
                            margin-top: 16px;
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        .ant-pagination-prev {
                            border-radius: 50px;
                            background: #FFF;
                            padding: 8px;
                        }

                        .ant-pagination-next {
                            border-radius: 50px;
                            background: #FFF;
                            padding: 8px;
                        }

                        .ant-pagination-item {
                            border: none;
                            background-color: transparent;
                            min-width: 24px;

                            a {
                                color: #000;
                                font-weight: 100;
                                opacity: 0.3;
                            }
                        }

                        .ant-pagination-item-active {

                            a {
                                color: #000;
                                font-weight: bold;
                                opacity: 1;
                            }
                        }

                        .ant-table-footer {
                            padding: 0px;
                            background: transparent;
                        }
                    }
                }

                &-daily {
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    width: 100%;
                    margin: 24px 0;

                    &-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-text {
                            color: #212121;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 21px;
                        }

                        &-button {
                            border-radius: 50px;
                            background: #033677;
                            padding: 10px 16px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            &-icon {
                                font-size: 24px;
                                color: #FFF;
                                margin-right: 8px;
                            }

                            &-text {
                                color: #FFF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    &-table {
                        margin-top: 32px;


                        .ant-table-tbody {
                            tr {
                                td {
                                    width: -1%;
                                    padding: 0px;
                                }
                            }
                        }

                        .ant-table-cell {
                            margin: 0px;
                            display: table-cell;
                            vertical-align: middle;

                        }

                        .ant-table-thead {
                            tr {
                                th {
                                    color: #B5B5C3;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    letter-spacing: 0.39px;
                                    background-color: transparent;
                                    padding: 0px 0px 16px 0px;
                                }
                            }
                        }

                        .ant-table-column-sorters {
                            justify-content: left;
                        }

                        .ant-table-column-title {
                            flex: none;
                        }

                        &-process {
                            display: flex;
                            align-items: center;
                            margin: 32px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-device {
                            display: flex;
                            align-items: center;
                            margin: 16px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-date {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;

                            &-title {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                            }

                            &-date {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;

                            }

                            &-time {
                                color: #B5B5C3;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                margin-top: 4px;
                            }
                        }

                        &-ipaddress {
                            display: flex;
                            align-items: center;
                            margin: 16px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-options {

                            &-edit {
                                font-size: 18px;
                                color: #3699FF;
                                padding: 8px;
                                border-radius: 50px;
                                background: #F3F6F9;
                            }

                            &-delete {
                                font-size: 18px;
                                color: #3699FF;
                                padding: 8px;
                                border-radius: 50px;
                                background: #F3F6F9;
                            }
                        }

                        &-footerbutton {
                            border: none;
                            background: transparent;
                            margin-top: 32px;

                            &-text {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                                text-decoration-line: underline;
                            }
                        }

                        .anticon-caret-up {
                            display: none;
                        }

                        .anticon-caret-down {
                            font-size: 20px;
                            color: #3699FF;
                        }


                        .ant-tag-cyan {
                            padding: 5px 9px;
                            border: none;
                            border-radius: 6px;
                            background: #C9F7F5;
                            color: #1BC5BD;
                            font-family: "Poppins", sans-serif;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            width: 92px;
                            text-align: center;
                        }

                        .ant-tag-yellow {
                            padding: 5px 9px;
                            border: none;
                            border-radius: 6px;
                            background: #FFF4DE;
                            color: #FFA800;
                            font-family: "Poppins", sans-serif;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            width: 92px;
                            text-align: center;
                        }

                        .ant-tag-red {
                            padding: 5px 9px;
                            border: none;
                            border-radius: 6px;
                            background: #FFE2E5;
                            color: #F64E60;
                            font-family: "Poppins", sans-serif;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            width: 92px;
                            text-align: center;
                        }

                        .notification-pagination {
                            display: flex;
                            justify-content: right;
                            margin-top: 16px;
                        }

                        .pagination-container {
                            display: flex;
                            align-items: center;
                            justify-content: right;
                            margin-top: 16px;
                        }

                        .pagination-label {
                            margin-right: 16px;
                            margin-top: 16px;
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        .ant-pagination-prev {
                            border-radius: 50px;
                            background: #FFF;
                            padding: 8px;
                        }

                        .ant-pagination-next {
                            border-radius: 50px;
                            background: #FFF;
                            padding: 8px;
                        }

                        .ant-pagination-item {
                            border: none;
                            background-color: transparent;
                            min-width: 24px;

                            a {
                                color: #000;
                                font-weight: 100;
                                opacity: 0.3;
                            }
                        }

                        .ant-pagination-item-active {

                            a {
                                color: #000;
                                font-weight: bold;
                                opacity: 1;
                            }
                        }

                        .ant-table-footer {
                            padding: 0px;
                            background: transparent;
                        }
                    }
                }

                &-activities {
                    border-radius: 30px;
                    background: #FFF;
                    padding: 32px;
                    width: 100%;
                    margin: 24px 0;

                    &-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-text {
                            color: #212121;
                            font-family: "Poppins", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 21px;
                        }

                        &-button {
                            border-radius: 50px;
                            background: #033677;
                            padding: 10px 16px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            &-icon {
                                font-size: 24px;
                                color: #FFF;
                                margin-right: 8px;
                            }

                            &-text {
                                color: #FFF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    &-table {
                        margin-top: 32px;


                        .ant-table-tbody {
                            tr {
                                td {
                                    width: -1%;
                                    padding: 0px;
                                }
                            }
                        }

                        .ant-table-cell {
                            margin: 0px;
                            display: table-cell;
                            vertical-align: middle;

                        }

                        .ant-table-thead {
                            tr {
                                th {
                                    color: #B5B5C3;
                                    font-family: "Poppins", sans-serif;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    letter-spacing: 0.39px;
                                    background-color: transparent;
                                    padding: 0px 0px 16px 0px;
                                }
                            }
                        }

                        .ant-table-column-sorters {
                            justify-content: left;
                        }

                        .ant-table-column-title {
                            flex: none;
                        }

                        &-process {
                            display: flex;
                            align-items: center;
                            margin: 32px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-device {
                            display: flex;
                            align-items: center;
                            margin: 16px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-date {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;

                            &-title {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                            }

                            &-date {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;

                            }

                            &-time {
                                color: #B5B5C3;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                margin-top: 4px;
                            }
                        }

                        &-ipaddress {
                            display: flex;
                            align-items: center;
                            margin: 16px 0px;

                            &-text {
                                color: #000;
                                font-family: "Poppins", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        &-options {

                            &-edit {
                                font-size: 18px;
                                color: #3699FF;
                                padding: 8px;
                                border-radius: 50px;
                                background: #F3F6F9;
                            }

                            &-delete {
                                font-size: 18px;
                                color: #3699FF;
                                padding: 8px;
                                border-radius: 50px;
                                background: #F3F6F9;
                            }
                        }

                        &-footerbutton {
                            border: none;
                            background: transparent;
                            margin-top: 32px;

                            &-text {
                                color: #3699FF;
                                font-family: "Poppins", sans-serif;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.39px;
                                text-decoration-line: underline;
                            }
                        }

                        .anticon-caret-up {
                            display: none;
                        }

                        .anticon-caret-down {
                            font-size: 20px;
                            color: #3699FF;
                        }

                        .notification-pagination {
                            display: flex;
                            justify-content: right;
                            margin-top: 16px;
                        }

                        .pagination-container {
                            display: flex;
                            align-items: center;
                            justify-content: right;
                            margin-top: 16px;
                        }

                        .pagination-label {
                            margin-right: 16px;
                            margin-top: 16px;
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        .ant-pagination-prev {
                            border-radius: 50px;
                            background: #FFF;
                            padding: 8px;
                        }

                        .ant-pagination-next {
                            border-radius: 50px;
                            background: #FFF;
                            padding: 8px;
                        }

                        .ant-pagination-item {
                            border: none;
                            background-color: transparent;
                            min-width: 24px;

                            a {
                                color: #000;
                                font-weight: 100;
                                opacity: 0.3;
                            }
                        }

                        .ant-pagination-item-active {

                            a {
                                color: #000;
                                font-weight: bold;
                                opacity: 1;
                            }
                        }

                        .ant-table-footer {
                            padding: 0px;
                            background: transparent;
                        }
                    }
                }



            }


        }
    }

    .ant-pagination {
        display: none;
    }



    &-modal {
        width: max-content !important;

        &-editprofile {

            &-profilephoto {
                border-radius: 90px;
                background: #D9D9D9;
                width: 128px;
                height: 128px;
                position: relative;
                margin-top: 26px;

                &-photo {
                    border-radius: 90px;
                    width: 128px;
                    height: 128px;
                }

                &-edit {
                    position: absolute;
                    padding: 8px;
                    border-radius: 100px;
                    background: #F3F6F9;
                    right: 0px;
                    top: 0px;
                    border: none;
                    cursor: pointer;

                    &-icon {
                        color: #3699FF;
                        font-size: 18px;
                    }

                }

                &-delete {
                    position: absolute;
                    padding: 8px;
                    border-radius: 100px;
                    background: #F3F6F9;
                    right: 0px;
                    bottom: 0px;
                    border: none;
                    cursor: pointer;

                    &-icon {
                        color: #3699FF;
                        font-size: 18px;
                    }

                }
            }

            &-namearea {
                display: flex;
                flex-direction: column;
                margin: 24px 0 0 0;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 8px;
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }
            }

            &-emailarea {
                display: flex;
                flex-direction: column;

                &-title {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 8px;
                }

                &-input {
                    .ant-input {
                        border: none;
                        border-radius: 100px;
                        padding: 12px 16px;
                    }

                    ::placeholder {
                        color: #000;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 0.5;
                    }
                }
            }

            &-sendbutton {
                display: flex;
                justify-content: flex-end;

                &-style {
                    width: 158px;
                    padding: 24px;
                    border-radius: 50px;
                    background: #033677;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-text {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: -0.5px;
                }
            }
        }


        .ant-modal-content {
            width: 760px;
            padding: 32px;
            border-radius: 30px;
            background: #EFEFEF;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .ant-modal-close {
            margin-top: 20px;
            margin-right: 20px;
            width: 36px;
            height: 36px;
            padding: 8px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }
    }
}