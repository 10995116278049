.auth {

  margin: 0;

  &-background {
    height: 100svh;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-content {

    &-title {
      font-family: "Poppins", serif;
      font-weight: 600;
      font-size: 48px;
      text-align: center;
      color: #033677;
      margin-bottom: 48px;
    }

    &-form {
      background-color: #fff;
      padding: 48px;
      border-radius: 30px;
      width: 412px;


      &-login {
        &-title {
          color: var(--UI-Black, #000);
          font-family: "Poppins", serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
          text-align: center;
          margin-bottom: 48px;
        }

        &-input {
          .ant-input-affix-wrapper {
            border: none !important;
            border-radius: 100px !important;
            background-color: #FAFAFA !important;
            padding: 12px 16px !important;

            .ant-input-prefix {
              width: 24px;
              height: 24px;
              margin-right: 8px;

              i {
                width: 24px;
                height: 24px;
                font-size: 24px;
              }
            }

            .ant-input-suffix {
              width: 24px;
              height: 24px;
              margin-left: 8px;

              svg {
                width: 24px;
                height: 24px;
                font-size: 24px;
              }
            }

            input {
              font-family: Poppins;
              font-size: 14px;
            }
          }
        }

        &-button {
          .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 8px;

            button {
              font-family: "Poppins", serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: -0.5px;
              padding: 27px 45px;
              background-color: #033677;
              border-radius: 50px;
            }
          }
        }

        .ant-checkbox-inner {
          border-radius: 100%;
          border-color: #033677;
          width: 18px;
          height: 18px;

          &:hover {
            background-color: #033677;
          }
        }



        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #033677;

            &:hover {
              background-color: #033677;
            }
          }
        }

        .ant-checkbox,
        .ant-checkbox-wrapper {
          .ant-checkbox-inner {

            &:hover,
            &:focus {
              background-color: #033677 !important;
            }
          }
        }

        .ant-form-item {
          margin-bottom: 16px;
        }
      }
    }
  }
}