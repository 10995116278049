.AdminLayout {
    display: flex;
    flex-wrap: wrap;

    .wrap {
        width: 100%;
        margin-top: 72px;
        margin-left: 200px;
        padding: 19px 24px 19px 0px;


        &.active {
            margin-left: 265px;
            padding: 19px 24px;

        }
    }
}