.expenses {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 30px;
    background: #FFF;
    height: calc(100% - 150px);

    &-header {
        display: flex;
        flex-direction: column;

        &-title {
            color: #212121;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
        }

        &-buttonarea {
            display: flex;
            justify-content: space-between;
            margin-top: 32px;

            &-allbutton {
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                text-decoration: none;
                border: none;
                cursor: pointer;

                &-text {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    text-decoration-line: underline;
                }
            }


            &-confirmselected {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 50px;
                background: rgba(3, 54, 119, 0.50);
                height: 38px;
                padding: 10px 16px;
                border: none;
                cursor: pointer;

                &-text {
                    color: #FFF;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }

    &-table {
        margin-top: 32px;

        .ant-table-tbody {
            tr {
                td {

                    padding: 8px;
                }
            }
        }

        .ant-table-cell {
            margin: 0px;
            display: table-cell;
            vertical-align: middle;
        }

        .ant-table-thead {
            tr {
                th {
                    color: #B5B5C3;
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.39px;
                    background-color: transparent;
                    padding: 0px 0px 16px 0px;
                }
            }
        }

        .ant-table-column-sorters {
            justify-content: left;
        }

        .ant-table-column-title {
            flex: none;
        }

        &-price {
            display: flex;
            align-items: center;
            margin: 16px 0px;
            ;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-plate {
            display: flex;
            align-items: center;
            margin: 16px 0px;
            ;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-point {
            display: flex;
            align-items: center;
            margin: 16px 0px;
            ;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-users {
            display: flex;
            align-items: center;
            margin: 16px 0px;
            ;

            &-profilephoto {
                border-radius: 50px;
                background: #D9D9D9;
                width: 44px;
                height: 44px;
                margin-right: 14px;
            }

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-expenses {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: none;
            background: transparent;
            cursor: pointer;

            &-text {
                color: #000;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            &-icon {
                display: flex;
                font-size: 18px;
                color: #3699FF;
                padding: 8px;
                border-radius: 50px;
                background: #F3F6F9;
                border: none;
                margin-right: 4px;

            }
        }

        &-date {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-title {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.39px;
            }

            &-date {
                color: #3699FF;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }

            &-time {
                color: #B5B5C3;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 4px;
            }
        }

        &-options {

            &-notverified {
                display: flex;
                padding: 10px 16px;
                border-radius: 50px;
                background: #000;
                border: none;
                cursor: pointer;

                &-text {
                    color: #FFF;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            &-verified {
                display: flex;
                padding: 10px 16px;
                border-radius: 50px;
                border: 1.5px solid #000;
                background: #FFF;
                cursor: pointer;

                &-text {
                    color: #000;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }

    &-viewexpenses {

        &-body {
            display: flex;
            height: 650px;
            background: #FFF;
            margin: 16px 0px;
        }

        &-image {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: 0 auto;
            display: block;
        }

        &-buttonarea {
            border-radius: 32px;
            background: #EFEFEF;
            padding: 32px;
            display: flex;
            justify-content: center;

            &-button {


                &-block {
                    display: flex;
                    font-size: 18px;
                    color: #F64E60;
                    padding: 8px;
                    border-radius: 50px;
                    background: #FFE2E5;
                    border: none;
                    cursor: pointer;
                    margin-right: 16px;
                }

                &-delete {
                    display: flex;
                    font-size: 18px;
                    color: #3699FF;
                    padding: 8px;
                    border-radius: 50px;
                    background: #F3F6F9;
                    border: none;
                    cursor: pointer;
                }
            }
        }

        &.ant-modal {
            top: 20px !important;
        }

        .ant-modal-content {
            box-shadow: none;
            background: transparent;
            width: 430px;
        }

        .ant-modal-header {
            background: transparent;
        }

        .ant-modal-title {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 32px;
            background: #EFEFEF;
            padding: 32px;
        }

        .ant-modal-close {
            margin-top: 40px;
            margin-right: 45px;
            width: 36px;
            height: 36px;
            padding: 8px;
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.20);
        }

    }

    .anticon-caret-up {
        display: none;
    }

    .anticon-caret-down {
        font-size: 20px;
        color: #3699FF;
    }

    .users-pagination {
        display: flex;
        justify-content: right;
        margin-top: 16px;
    }

    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
    }

    .pagination-label {
        margin-right: 16px;
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ant-pagination-prev {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-next {
        border-radius: 50px;
        background: #FFF;
        padding: 8px;
    }

    .ant-pagination-item {
        border: none;
        background-color: transparent;
        min-width: 24px;

        a {
            color: #000;
            font-weight: 100;
            opacity: 0.3;
        }

    }

    .ant-pagination-item-active {

        a {
            color: #000;
            font-weight: bold;
            opacity: 1;
        }
    }

    .ant-table-thead {
        tr {
            th {
                &::before {
                    display: none !important;
                }
            }
        }
    }

    .ant-tag-cyan {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #C9F7F5;
        color: #1BC5BD;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .ant-tag-yellow {
        padding: 5px 9px;
        border: none;
        border-radius: 6px;
        background: #FFF4DE;
        color: #FFA800;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 92px;
        text-align: center;
    }

    .ant-divider-horizontal {
        display: none;
    }
}